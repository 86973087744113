<template>
  <v-card
    max-width="244px"
    max-height="320px"
    class="text-center indicated-card d-flex align-center flex-column pa-3"
  >
    <v-avatar>
      <v-img
        alt="Avatar"
        :src="nominated.photo_url"
      />
    </v-avatar>
    <v-card-title class="indicated-title">
      {{ nominated.pretty_name }}
    </v-card-title>
    <v-card-text class="text-left indicated-subtitle">
      <span class="text-bold">
        {{ $t('components.nomination_card.@nominated_name', { name: nominated.name }) }}
      </span>
      <span>{{ justification }}</span>
      <p class="text-bold">
        {{
          $t('components.nomination_card.quote_author', { name: user.name })
        }}
      </p>
    </v-card-text>
    <div
      v-if="canLike"
      class="indicated-action d-flex pa-5"
    >
      <v-icon
        v-if="hasLiked"
        class="icon pr-2"
        color="#0db551"
        @click="like(nominationId)"
      >
        mdi-thumb-up
      </v-icon>
      <v-icon
        v-else
        class="icon pr-2"
        @click="like(nominationId)"
      >
        mdi-thumb-up-outline
      </v-icon>
      <span class="posts-icon-text"> {{ likes.length }} </span>
    </div>
    <div
      v-else
      class="cant-vote d-flex pa-6"
    >
      <v-tooltip
        bottom
        max-width="200px"
        :top="true"
        :right="true"
        open-delay="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-flex"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="icon pr-2">
              mdi-thumb-up-outline
            </v-icon>
            <span class="posts-icon-text"> {{ likes.length }} </span>
          </div>
        </template>
        <div>
          <span class="d-flex text-center tooltip-warning">
            {{ $t('components.nomination_card.user_cant_like') }}
          </span>
        </div>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NominationCard',
  props: {
    justification: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => {},
    },
    nominated: {
      type: Object,
      default: () => {},
    },
    likes: {
      type: Array,
      default: () => [],
    },
    liked: {
      type: Boolean,
      default: false,
    },
    nominationId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      hasLiked: this.liked,
    };
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'getValhallaId',
    ]),
    canLike() {
      return this.user.id !== this.currentUser.id && this.currentUser.id !== this.nominated.id;
    },
  },
  methods: {
    ...mapActions([
      'doNominationLike',
    ]),

    like(id) {
      this.doNominationLike({ nominated_id: id, valhalla_id: this.getValhallaId });
      this.hasLiked = !this.hasLiked;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicated-card {
  border-radius: 12px;
  height: 320px;
  min-width: 240px;
  margin: 0 auto;

  .indicated-title {
    font: 16px/24px MontserratBold;
    letter-spacing: 0.14px;
    white-space: nowrap;
  }
  .indicated-subtitle {
    font: 14px/20px NunitoRegular;
    color: $text-color;
    letter-spacing: 0.14px;
    overflow-y: auto;
    height: 140px;
    text-align: left;
    white-space: normal;

    .text-bold {
      font-weight: bold;
    }
  }

  .indicated-action {
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    width: 55%;

      .icon {
      max-height: 44px;
      max-width: 44px;
    }
    .posts-icon-text {
      font-family: MontserratRegular;
      font-size: 16px;
    }
  }

  .cant-vote {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 55%;

    .icon {
      max-height: 44px;
      max-width: 44px;
      opacity: 0.3;
    }
    .posts-icon-text {
      font-family: MontserratRegular;
      font-size: 16px;
    }
  }
  .indicated-subtitle::-webkit-scrollbar {
    width: 10px;
  }

  .indicated-subtitle::-webkit-scrollbar-track {
    background: $disabled;
    border-radius: 40px;
  }

  .indicated-subtitle::-webkit-scrollbar-thumb {
    background-color: $text-input-post;
    border-radius: 40px;
  }
}
.v-tooltip__content {
  background-color: $background !important;
  font: 12px/16px MontserratMedium !important;
  box-shadow: 0px 4px 8px $shadow-orange !important;

  .tooltip-warning {
    color: $post-improve !important;
  }
}

@media screen and (max-width: $md) {
  .indicate-card {
    .indicate-title {
      width: 100%;
      word-break: break-word;
    }
  }
}
</style>
