<template>
  <div
    class="backdrop"
    @click.stop="$emit('close-modal')"
  >
    <div
      class="modal"
      @click.stop
      @mousedown.stop
    >
      <h1>{{ $t('views.winners.admin_see_winners') }}</h1>
      <div class="winners-container">
        <Winner
          :photo-url="firstPlace.photo_url"
          :name="firstPlace.name"
          :nominations-received="firstPlace.nominations_received"
          :likes="firstPlace.nomination_likes"
          :final-count="firstPlace.final_count"
        />
        <div
          v-if="secondPlace"
          class="separator"
        />
        <Winner
          v-if="secondPlace"
          :photo-url="secondPlace.photo_url"
          :name="secondPlace.name"
          :nominations-received="secondPlace.nominations_received"
          :likes="secondPlace.nomination_likes"
          :final-count="secondPlace.final_count"
        />
        <div
          v-if="thirdPlace"
          class="separator"
        />
        <Winner
          v-if="thirdPlace"
          :photo-url="thirdPlace.photo_url"
          :name="thirdPlace.name"
          :nominations-received="thirdPlace.nominations_received"
          :likes="thirdPlace.nomination_likes"
          :final-count="thirdPlace.final_count"
        />
      </div>
      <v-btn
        height="48px"
        width="188px"
        x-large
        class="mb-3 text-capitalize valhalla-btn"
        color="primary"
        @click="$router.push('/valhalla/winners')"
      >
        {{ $t('views.valhalla.button_valhalla') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Winner from '@/components/globals/FinishValhallaModalWinner.vue';

export default {
  emits: ['close-modal'],
  components: {
    Winner,
  },
  computed: {
    firstPlace() {
      return this.$store.getters.getValhallaWinners.at(0);
    },
    secondPlace() {
      return this.$store.getters.getValhallaWinners.at(1);
    },
    thirdPlace() {
      return this.$store.getters.getValhallaWinners.at(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: $modal-width;
  height: $modal-height;
  background-color: $modal-background;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  padding: 40px;
  height: clamp(400px, 84%, 680px);
  width: clamp(604px, 64%, 940px);
  background: $card-background;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px $shadow-green;
  position: relative;
  h1 {
    font: 600 34px/36px MontserratRegular;
    color: $primary;
    text-align: center;
  }
  .winners-container {
    display: flex;
    height: 100%;
    padding: 40px 0;
    gap: 48px;
  }
  .separator {
    width: 0px;
    height: 95%;
    border: 1px solid $separator;
    opacity: 0.8;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 28px;
    cursor: pointer;
    width: 20px;
  }

  .valhalla-btn {
    position: absolute;
    bottom: 20px;
  }
}
</style>
