<template>
  <div class="winner-card">
    <v-avatar
      min-width="88px"
      height="88px"
      min-height="88px"
    >
      <v-img
        alt="Avatar"
        :src="photoUrl"
      />
    </v-avatar>
    <div class="winner-name justify-center">
      {{ name }}
    </div>
    <div class="winner-votes">
      <v-img
        class="icon"
        src="../../assets/img/star-green.svg"
      />
      {{ finalCount }}
    </div>
    <div
      class="winner-voters"
    >
      <p
        v-for="nomination in nominationsReceived"
        :key="nomination.id"
        class="nomination-names d-flex flex-row align-center ml-0"
      >
        {{ nomination.user_who_nominated.name }}
        <v-icon
          small
          class="ml-1"
          color="#7c12cd"
        >
          mdi-comment-outline
        </v-icon>
      </p>
      <p
        v-for="like in likes"
        :key="like.id"
        class="d-flex flex-row align-center ml-0"
      >
        {{ like.user.name }}
        <v-icon
          small
          class="ml-1"
          color="#0db551"
        >
          mdi-thumb-up-outline
        </v-icon>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    photoUrl: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    nominationsReceived: {
      type: Array,
      required: true,
    },
    likes: {
      type: Array,
      default: () => [],
    },
    finalCount: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.winner-card {
  height: 100%;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .winner-name {
    text-align: center;
    font: 18px/24px MontserratBold;
    color: $form-filter-to-indicate;
  }

  .winner-votes {
    display: flex;
    align-items: center;
  }

  .winner-voters {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    overflow-y: auto;
    padding: 0 16px;
    padding-bottom: 16px;

    p {
      margin: 4px;
    }
    .nomination-names {
    color: $post-todo;
  }
  }

  .winner-voters::-webkit-scrollbar {
    width: 4px;
    height: 80%;
  }

  .winner-voters::-webkit-scrollbar-track {
    background: $disabled;
    border-radius: 12px;
  }

  .winner-voters::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 12px;
  }
}
</style>
