<template>
  <v-container fluid>
    <div class="text-center">
      <div class="valhalla-text">
        <p>
          {{ $t('views.valhalla.vote_to_valhalla') }}
        </p>
      </div>
    </div>
    <div class="nominations-container">
      <indicate-viking />
      <nomination-card
        v-for="nomination in getValhallaNominations"
        :key="nomination.id"
        :nomination-id="nomination.id"
        :justification="nomination.justification"
        :user="nomination.user"
        :nominated="nomination.nominated"
        :likes="nomination.likes"
        :liked="nomination.user_liked"
      />
    </div>
    <v-snackbar
      v-model="snackbar"
      color="primary"
      class="snackbar"
      width="572"
      height="48"
    >
      <span class="ml-4 snackbar-text">
        {{ $t('views.valhalla.nomination_success') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          class="mr-4 no-background"
          @click="snackbar = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <finish-valhalla-modal
      v-if="seeFinishModal"
      @close-modal="closeModal = true"
    />
    <v-row
      class="button-container d-flex justify-center align-center"
    >
      <v-btn
        v-if="currentUser?.admin"
        x-large
        class="mb-3 text-capitalize btn admin_btn"
        @click="confirmationModal"
      >
        {{ $t('views.valhalla.button_admin') }}
      </v-btn>
      <confirmation-modal
        ref="popupRef"
        @finishValhalla="finishValhalla"
      />
      <v-btn
        x-large
        class="mb-3 text-capitalize btn valhalla-btn"
        color="primary"
        @click="$router.push('/valhalla/winners')"
      >
        {{ $t('views.valhalla.button_valhalla') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import IndicateViking from '../components/globals/IndicateViking.vue';
import NominationCard from '../components/globals/NominationCard.vue';
import FinishValhallaModal from '../components/globals/FinishValhallaModal.vue';
import ConfirmationModal from '../components/globals/ConfirmationModal.vue';

export default {
  components: {
    IndicateViking,
    NominationCard,
    FinishValhallaModal,
    ConfirmationModal,
  },
  data() {
    return {
      snackbar: false,
      closeModal: false,
    };
  },

  computed: {
    ...mapGetters(['getValhallaNominations', 'currentUser', 'showFinishValhallaModal']),
    seeFinishModal() {
      return this.showFinishValhallaModal && !this.closeModal;
    },
  },
  mounted() {
    this.fetchValhallaNominations();
    if (this.$route.params.created) {
      this.snackbar = true;
    }
  },
  methods: {
    ...mapActions(['fetchValhallaNominations', 'finishValhalla']),
    confirmationModal() {
      this.$refs.popupRef.show();
    },
  },
};
</script>
<style lang="scss">
.valhalla-text {
  height: 44px;
  font: 34px/36px MontserratRegular;
  color: $primary;
}

.nominations-container {
  /* fits at most 6 cards per row */
  max-width: 1700px;

  margin: 48px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  grid-gap: 28px;
  justify-content: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  bottom: 20px;
  width: 100%;
  position: fixed;
  z-index: 5;
}

.admin_btn.theme--light.v-btn {
  border: 1px solid $primary;
  color: $primary;
}

@media screen and (max-width: $sm) {
  .button-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .nominations-container {
    margin: 70px auto;
  }
}
</style>
