<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    max-width="244px"
    max-height="320px"
    class="text-center indicate-card d-flex align-center flex-column pa-3"
  >
    <v-avatar>
      <v-img src="../../assets/img/indicate-valhalla.svg" />
    </v-avatar>

    <v-card-title class="indicate-title">
      {{ $t('components.indicate-viking.indicate-someone') }}
    </v-card-title>

    <v-card-text
      class="text-left indicate-subtitle"
      v-html="$t('components.indicate-viking.indicate-text')"
    />
    <v-btn
      class="mb-3 text-capitalize indicate-btn"
      @click="doIndicate"
    >
      {{ $t('components.indicate-viking.indicate-viking') }}
    </v-btn>
  </v-card>
</template>

<script>
import { trackEvent } from '../../plugins/mixpanel';

export default {
  name: 'IndicateViking',
  methods: {
    doIndicate() {
      trackEvent('clicou_indicar_viking');
      this.$router.push('valhalla/indicate');
    },
  },
};
</script>

<style lang="scss" scoped>
.indicate-card {
  box-shadow: 0px 8px 16px $shadow-green !important;
  border-radius: 12px;
  height: 320px;
  margin: 0 auto;
  min-width: 240px;
  background-color: $primary;

  .indicate-title {
    font: 16px/24px MontserratBold;
    color: $text-light;
    letter-spacing: 0.14px;
    white-space: nowrap;
  }
  .indicate-subtitle {
    font: 14px/20px NunitoRegular;
    color: $text-light;
    letter-spacing: 0.14px;
    overflow-y: auto;
    height: 140px;
    text-align: left;
    white-space: normal;
  }
  .indicate-btn {
    font: 14px/20px NunitoBold;
    background-color: $card-background;
    color: $primary;
  }
}
</style>
