<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    persistent
  >
    <v-card
      class="popup-card"
    >
      <v-card-title class="popup-title">
        {{ $t('views.valhalla.ending_valhalla.title') }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="popup-text popup-btn cancel shadow-cancel"
          @click="cancelDelete"
        >
          {{ $t('views.valhalla.ending_valhalla.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="popup-text popup-btn yes shadow-yes"
          @click="finish"
        >
          {{ $t('views.valhalla.ending_valhalla.confirm') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    finish() {
      this.dialog = false;
      this.$emit('finishValhalla');
    },
    cancelDelete() {
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/scss/components/globals/popup.scss"/>
